import React from 'react'
import { Link } from 'gatsby';

import Layout from '../Layout';
import Button from '../components/Button'
import Header from '../components/Header';
import Paragraph from '../components/Paragraph';


const NotFoundPage = () => (
  <Layout noNav>
    <Header>404</Header>
    <Paragraph>Hello, is it me you lookin' for?</Paragraph>
    <Button as={Link} to="/">Nah</Button>
  </Layout>
)

export default NotFoundPage
